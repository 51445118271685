<template>
  <a-row class="userManagement">
    <a-row type="flex" align="middle" style="margin-bottom: 50px">
      <a-col :span="6" class="d-flex justify-content-left">
        <div class="title-page">
          {{ $t('userManage.user_management') }}
        </div>
      </a-col>
      <a-col
        :span="12"
        class="d-flex justify-content-center align-items-center"
      >
        <div class="search-box mx-2">
          <SearchIcon class="i" style="color: #999999" />
          <input
            v-model="querySearch"
            class="field"
            type="text"
            :placeholder="$t('userManage.search')"
            @input="handleSearch"
          />
        </div>

        <div class="mx-2 filter-box" @click="handleShowModal('filter', true)">
          <div>
            <FilterIcon style="color: #999999" />
          </div>
          <div class="caption">
            Filter
          </div>
        </div>
      </a-col>
      <a-col :span="6" class="add-user d-flex justify-content-end">
        <a-button v-if="user.length && permission.includes('WRITE')" class="accept-button" @click="toAddPage">
          {{ $t('userManage.add_user') }}
        </a-button>
      </a-col>
    </a-row>

    <!-- Modal filter user -->
    <FilterUserList
      :show-modal-filter-user="showModalFilterUser"
      :business-roles="businessRoles"
      @handleShowModal="handleShowModal"
      @resetFilter="resetFilter"
    />
    <!-- Filter list active -->
    <a-col
      v-if="getFilterParam"
      class="d-flex justify-content-center"
      style="margin: -40px 0 50px 0"
    >
      <div v-for="(item, index) in activeFilter" :key="index" class="tag-box">
        <p>{{ item }}</p>
      </div>
      <a-button
        class="cancel-button pbz-font d-inline"
        style="width: auto; height: auto; border: none"
        @click="resetFilter"
      >
        {{ $t('utils.clearFilter') }}
      </a-button>
    </a-col>

    <!-- Page if User List empty -->
    <a-col
      v-if="!user.length"
      :span="24"
      style="padding: calc(100vh / 5) 0"
      class="empty d-flex justify-content-center flex-column align-items-center"
    >
      <div
        style="
          width: 180px;
          height: 180px;
          background: #f2f2f2;
          border-radius: 50%;
          margin-bottom: 40px;
        "
      >
        <UserSetting style="margin-top: 20px" />
      </div>

      <div class="caption-one pbz-font subtitle-md-medium">
        Tidak Ada Pengguna
      </div>

      <div class="caption-two pbz-font body-md-regular">
        Saat ini anda <br />
        belum memiliki data pengguna
      </div>
      <!-- {{ accessMenu }} -->

      <div class="button-reload pbz-font button-md-bold">
        <div @click="toAddPage">
          Tambah Pengguna
        </div>
      </div>
    </a-col>

    <!-- List User -->
    <a-collapse v-model="activeKey" style="border: 0">
      <a-collapse-panel v-for="(item) in user" :key="item.id" :show-arrow="false" class="content-box">
        <template slot="header">
          <a-row class="d-flex" :gutter="16">
            <a-col :span="8">
              <div class="d-flex" style="color: #999999">
                Username
              </div>
              <div class="d-flex" style="word-break: break-word;">
                {{ item.username }}
              </div>
            </a-col>
            <a-col :span="6">
              <div class="d-flex" style="color: #999999">
                Nama Pengguna
              </div>
              <div class="d-flex" style="word-break: break-word;">
                {{ item.full_name }}
              </div>
            </a-col>
            <a-col :span="5">
              <div class="d-flex" style="color: #999999">
                Role
              </div>
              <div class="d-flex" style="word-break: break-word;">
                {{
                  $route.query.workspace_id
                    ? item.user_workspaces.find(
                      (x) =>
                        x.workspace_role.workspace_id ===
                        $route.query.workspace_id,
                    ).workspace_role.role.name
                    : item.user_workspaces.find((x) => x.is_default)
                      .workspace_role.role.name || ''
                }}
              </div>
            </a-col>
            <a-col :span="4" class="d-flex justify-content-end">
              <a-button
                v-if="!permission.includes('WRITE')"
                :class="`${statusButton(item.status)} ant-btn accept-button`"
                :style="{
                  marginTop: '5px',
                  background: item.status === 'ACTIVE' ? '#2D9500' : '',
                }"
                @click.prevent=""
              >
                {{ statusText(item.status) }}
              </a-button>
              <a-dropdown v-else>
                <a-menu v-if="item.status !== 'INVITATION'" slot="overlay">
                  <a-menu-item
                    key="2"
                    class="pbz-font"
                    @click="handleDropdownStatus(item)"
                  >
                    {{
                      item.status.toLowerCase() === 'active'
                        ? 'Non Aktifkan'
                        : 'Aktifkan'
                    }}
                  </a-menu-item>
                </a-menu>
                <a-button
                  :class="statusButton(item.status)"
                  :style="{
                    marginTop: '5px',
                    background: item.status === 'ACTIVE' ? '#2D9500' : '',
                  }"
                >
                  {{ statusText(item.status) }}
                  <ArrowIcon
                    v-if="item.status !== 'INVITATION'"
                    style="
                      color: #ffffff;
                      margin-left: 10px;
                      height: 8px;
                      width: 8px;
                    "
                    :rotate="'rotate(180)'"
                  />
                </a-button>
              </a-dropdown>
            </a-col>
            <a-col :span="1" class="d-flex justify-content-center">
              <div style="margin-top: 10px">
                <ArrowIcon :rotate="arrowRotate(item.id)" />
              </div>
            </a-col>
          </a-row>
        </template>
        <a-row class="d-flex" :gutter="16">
          <a-col :span="8">
            <div>
              <div class="d-flex" style="color: #999999">
                Email
              </div>
              <div class="d-flex" style="color: #0559cb; word-break: break-word;">
                {{ item.email }}
              </div>
            </div>
            <div style="margin: 10px 0px">
              <div class="d-flex" style="color: #999999">
                No. Handphone
              </div>
              <div class="d-flex" style="word-break: break-word;">
                +62
                {{
                  item.phone_number !== null
                    ? item.phone_number.replace(/^0|\+.{2}|^62/, '')
                    : ''
                }}
              </div>
            </div>
            <div>
              <div class="d-flex" style="color: #999999">
                Dibuat
              </div>
              <div class="d-flex" style="word-break: break-word;">
                {{ $moment(item.created_at).format('DD MMM YYYY HH:MM') }}
              </div>
            </div>
          </a-col>
          <a-col :span="13">
            <div
              v-if="
                !isWarehouseOperator(
                  fetchWorkspace(item.user_workspaces).workspace_role
                    .restriction_base,
                )
              "
            >
              <div class="d-flex" style="color: #999999">
                Bisnis
              </div>
              <div
                v-for="(list, index) in fetchWorkspace(item.user_workspaces)
                  .restriction_list"
                :key="index"
                class="d-flex"
                style="word-break: break-word;"
              >
                {{ fetchBusiness(list) }}
              </div>
            </div>
            <div
              v-if="
                isWarehouseOperator(
                  fetchWorkspace(item.user_workspaces).workspace_role
                    .restriction_base,
                )
              "
            >
              <div class="d-flex" style="color: #999999">
                Warehouse
              </div>
              <div
                v-for="(warehouse, i) in fetchWorkspace(
                  item.user_workspaces,
                ).restriction_list"
                :key="i"
                class="d-flex"
              >
                {{ fetchWarehouse(warehouse) }}
              </div>
            </div>
          </a-col>
          <a-col :span="3">
            <div class="box-button">
              <!-- <a-button
                v-if="permission.includes('WRITE')"
                :disabled="item.status === 'INVITATION'"
                class="action-button"
                style="margin-top: 15px"
                @click="toEditPage(item.id)"
              >
                <Pencil class="icon-button" />
                Ubah
              </a-button> -->
              <a-button
                v-if="permission.includes('WRITE')"
                :disabled="item.status === 'INVITATION'"
                class="action-button"
                style="margin-top: 15px"
                @click="
                  handleShowModal('del', true, item.id, item.username)
                "
              >
                <TrashIcon class="icon-button" />
                Hapus
              </a-button>
              <!-- <a-button
                class="action-button"
                @click="handleShowModal('history', true, item.id)"
              >
                <Clock class="icon-button" />
                Riwayat
              </a-button> -->
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-col
      v-if="user.length"
      :span="24"
      style="margin-top: 24px"
      class="d-flex justify-content-end"
    >
      <Pagination
        :total="total_row"
        :page-size="limit"
        :total-length-per-page="user.length"
        :default-page="page"
        @changePage="changePage"
      />
    </a-col>
    <ModalUserManagement
      :show-modal-del-user="showModalDelUser"
      :show-modal-history-user="showModalHistoryUser"
      :show-modal-edit-user="showModalEditUser"
      :data-history="dataHistory"
      @handleShowModal="handleShowModal"
      @submitEditUser="submitEditUser"
      @submitDeleteUser="submitDeleteUser"
    />
  </a-row>
</template>

<script>
// import { mapState } from 'vuex'
import { findUser, getWorkspaceRoles, getUserHistory, updateUser, deleteUser } from '@/api/user'
import { getBusiness } from '@/api/business'
import SearchIcon from '@/components/Icons/Search.vue'
import FilterIcon from '@/components/Icons/Filter.vue'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import Pencil from '@/components/Icons/Pencil.vue'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import Clock from '@/components/Icons/Clock.vue'
import UserSetting from '@/components/Icons/UserSetting.vue'
import ModalUserManagement from '@/components/UserManagement/ModalUserManagement/index.vue'
import FilterUserList from '@/components/UserManagement/Filter/index.vue'
import Pagination from '@/components/Pagination'
import getQueryParam from '@/utils/getQueryParam'
import { EXCLUDED_ROLE } from '@/services/store/role.js'

export default {
  components: {
    SearchIcon,
    FilterIcon,
    ArrowIcon,
    Pencil,
    TrashIcon,
    Clock,
    UserSetting,
    ModalUserManagement,
    FilterUserList,
    Pagination,
  },
  mixins: [getQueryParam],
  data: function () {
    return {
      showModalEditUser: false,
      showModalDelUser: false,
      showModalHistoryUser: false,
      showModalFilterUser: false,
      user: [],
      businessRoles: [],
      userWillUpdated: {},
      userWillDeleted: {},
      dataHistory: [],
      userHistory: '',
      warehouse_list: [],
      business_list: [],
      total_row: 0,
      querySearch: '',
      activeKey: [],
    }
  },
  computed: {
    // ...mapState(['user', 'userMenu']),
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
    // accessMenu() {
    //   const access_menu = this.$store.state.user.userMenu?.[1].children.filter(
    //     (val) => val.url.includes(this.$route.path),
    //   )?.[0].access || null
    //   return access_menu
    // },
    getFilterParam() {
      if (
        this.$route.query.username ||
        this.$route.query.email ||
        this.$route.query.workspace_role_id
      ) {
        return true
      } else {
        return false
      }
    },
    activeFilter() {
      const usernameParam = this.$route.query.username
        ? this.$route.query.username.split(',')
        : []
      const emailParam = this.$route.query.email
        ? this.$route.query.email.split(',')
        : []
      const rolesParam = this.$route.query.workspace_role_id
        ? this.businessRoles
            .filter(
              (item) =>
                item.workspace_role_id === this.$route.query.workspace_role_id,
            )
            .map((item) => {
              return item.role.name
            })
        : []
      const activeValue = [...usernameParam, ...emailParam, ...rolesParam]
      return activeValue.filter((item) => item !== null)
    },
    page: function () {
      return +this.$route.query.page || 1
    },
    limit: function () {
      return +this.$route.query.limit || 20
    },
  },
  watch: {
    '$route.query'() {
      this.fetchUserList()
    },
    // permission(newValue) {
    //   if(!newValue.length) {
    //    this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted() {
    this.fetchUserList()
    this.getWarehouseList()
    this.getBusinessList()
    this.fetchRole()
  },
  methods: {
    isWarehouseOperator(param) {
      if (param !== null) {
        return param.toLowerCase() === 'warehouse'
      } else {
        return false
      }
    },
    arrowRotate(param) {
      return this.activeKey.includes(param) ? 'rotate(180)' : 'rotate(0)'
    },
    showDetail(param, id) {
      this.user.find((item) => item.id === id).collapse = param
    },
    handleShowModal(type, param, param_id, param_username) {
      switch (type) {
        case 'edit':
          this.showModalEditUser = param
          break
        case 'del':
          this.showModalDelUser = param
          this.userWillDeleted = {
            id: param_id,
            username: param_username,
          }
          break
        case 'history':
          this.userHistory = param_id
          this.fetchUserHistory()
          this.showModalHistoryUser = param
          break
        case 'filter':
          this.showModalFilterUser = param
          break
        case 'close':
          this.showModalEditUser = param
          this.showModalDelUser = param
          this.showModalHistoryUser = param
          this.showModalFilterUser = param
          break
      }
    },
    handleDropdownStatus(param) {
      this.handleShowModal('edit', true)

      if (param.status.toLowerCase() === 'inactive') {
        this.userWillUpdated = {
          id: param.id,
          username: param.username,
          status: 'ACTIVE',
        }
      } else if (param.status.toLowerCase() === 'active') {
        this.userWillUpdated = {
          id: param.id,
          username: param.username,
          status: 'INACTIVE',
        }
      }
    },
    handleSearch(e) {
      const value = e.target.value
      this.querySearch = value.length ? value : undefined
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        return this.$router.push({
          query: {
            ...this.getQueryParam(this.$route.query),
            keyword: value !== '' ? value : undefined,
          },
        })
      }, 500)
    },
    statusButton(param) {
      switch (param.toLowerCase()) {
        case 'invitation':
          return 'invite-button'
        case 'inactive':
          return 'inactive-button'
        case 'active':
          return 'accept-button'
      }
    },
    statusText(param) {
      switch (param.toLowerCase()) {
        case 'invitation':
          return 'Diundang'
        case 'inactive':
          return 'Tidak Aktif'
        case 'active':
          return 'Aktif'
      }
    },
    fetchWorkspace(param) {
      return this.$route.query.workspace_id
        ? param.find(
            (x) =>
              x.workspace_role.workspace_id === this.$route.query.workspace_id,
          )
        : param.find((x) => x.is_default)
    },
    async fetchUserList() {
      const query = {
        ...this.$route.query,
        limit: this.limit,
        page: this.page,
      }
      await findUser(query)
      .then(({ data: { data, total_items } }) => {
        this.total_row = total_items
        const excludedRoleBasedOnLoggedUser = EXCLUDED_ROLE?.filter((obj) => obj.name !== this.$store.state?.user?.role_name)
        const finalExcludedRole = excludedRoleBasedOnLoggedUser?.map((obj) => obj.id)
        const filteredData = data?.filter((obj) => {
          return !finalExcludedRole.includes(obj?.user_workspaces[0]?.workspace_role?.role?.id)
            && obj.id !== '30d9817c-07ef-45b8-a36c-319f86734b29'
        })
        this.user = filteredData.map((item) => ({
          ...item,
          collapse: false,
        }))
      })
      .catch((err) => console.error(err))
    },
    fetchWarehouse(param) {
      return this.warehouse_list.find((item) => item.warehouse_id === param)
        ? this.warehouse_list.find((item) => item.warehouse_id === param).name
        : param
    },
    fetchBusiness(param) {
      if (param === '*') {
        return param
      } else {
        return this.business_list.find((item) => item.business_id === param)
          ? this.business_list.find((item) => item.business_id === param)
              .business_name
          : param
      }
    },
    getWarehouseList() {
      this.$store
        .dispatch('warehouse/GETSALESWAREHOUSE', {
          page: 1,
          limit: 1000,
        })
        .then(({ data }) => {
          this.warehouse_list = data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async getBusinessList() {
      await getBusiness()
      .then(({ data: { data: response } }) => this.business_list = response)
      .catch((err) => console.error(err))
    },
    async fetchUserHistory() {
      await getUserHistory()
      .then(({ data: { data: response } }) => this.dataHistory = response)
      .catch((err) => console.error(err))
    },
    async fetchRole() {
      await getWorkspaceRoles()
      .then(({ data: { data: response } }) => {
        const excludedRoleBasedOnLoggedUser = EXCLUDED_ROLE?.filter((obj) => obj.name !== this.$store.state?.user?.role_name)
        const finalExcludedRole = excludedRoleBasedOnLoggedUser?.map((obj) => obj.id)
        this.businessRoles = response.filter((obj) => !finalExcludedRole.includes(obj.role.id))
      })
    },
    toAddPage() {
      this.$router.push({
        name: 'invite-user',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
    toEditPage(param) {
      this.$router.push({
        name: 'edit-user',
        query: {
          ...this.getQueryParam(this.$route.query),
          uid: param,
        },
      })
    },
    async submitEditUser() {
      const payload = this.userWillUpdated
      delete payload.collapse

      await updateUser({
        type: '/status',
        data: payload,
      })
      .then(() => {
        this.$notification.success({
          message: 'Data Berhasil di update',
        })
        setTimeout(() => {
          this.fetchUserList()
          this.handleShowModal('close', false)
        }, 1000)
      })
      .catch((err) => {
        this.$notification.error({
          message: err,
        })
      })
    },
    async submitDeleteUser() {
      await deleteUser(this.userWillDeleted.id)
      .then(() => {
        this.$notification.success({
          message: 'Data Berhasil di hapus',
        })
        setTimeout(() => {
          this.$router.go()
        }, 1000)
      })
      .catch((err) => {
        this.$notification.error({
          message: err,
        })
      })
    },
    resetFilter() {
      this.$router.push({
        query: {
          ...this.$route.query,
          username: undefined,
          email: undefined,
          workspace_role_id: undefined,
        },
      })
      this.handleShowModal('close', false)
    },
    changePage: function (value) {
      this.$router.push({
        path: this.getPathUrl,
        query: { ...this.$route.query, page: value, order_id: undefined },
      })
    },
  },
}
</script>

<style lang="scss">
.userManagement {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  .title-page {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
  .field {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    text-align: left;
    padding-left: 2.5rem !important;
    padding: 10px 15px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    color: #999999;
  }
  .search-box {
    width: 338px;
  }
  .search-box > .i {
    position: absolute;
    margin: 1rem 0 0 0.5rem;
  }
  .field::placeholder {
    color: #999999 !important;
  }
  .filter-box {
    width: 93px;
    height: 48px;
    padding: 2px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    div {
      margin: 0 5px;
    }

    .caption {
      font-family: 'Poppins', sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #999999;
    }
  }
  .add-user {
    button {
      width: 196px;
      height: 48px;
    }
  }
  .content-box {
    margin: 10px 0;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
    border-radius: 8px;

    .ant-collapse-content {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      background-color: initial;
    }
  }
  .accept-button {
    width: auto;
    background: #e00000;
    border-radius: 5px;
    color: #ffffff;
  }
  .button-reload {
    padding: 12px 20px;
    position: static;
    width: 401px;
    height: 48px;
    background: #0559cb;
    border-radius: 5px;
    color: #ffffff;
    margin-top: 50px;
    cursor: pointer;
  }
  .invite-button {
    width: auto;
    background: #999999;
    border-radius: 5px;
    color: #ffffff;
    pointer-events: none;
  }
  .inactive-button {
    width: auto;
    background: #e00000;
    border-radius: 5px;
    color: #ffffff;
  }
  .box-button {
    background: rgb(242, 242, 242);
    display: inline-grid;
    height: 100%;
    width: 100%;
    justify-content: center;
    border-radius: 8px;

    .action-button {
      width: auto;
      border-radius: 5px;
      color: #0559cb;
    }
    .icon-button {
      height: 15px;
      width: auto;
      padding-right: 10px;
    }
  }
  .eclipse-icon {
    width: 180px;
    height: 180px;
    background: linear-gradient(153.43deg, #f49685 0%, #ec4426 83.33%);
  }

  .tag-box {
    display: inline-flex;
    position: static;
    width: auto;
    height: 23px;
    background: #4d4d4d;
    box-shadow: 0px 5px 5.3625px rgba(0, 0, 0, 0.055);
    border-radius: 33px;
    margin: 0 5px;
    padding: 3px 15px;
    p {
      position: static;
      color: #ffffff;
      align-items: center;
      text-align: center;
      font-size: 11px;
    }
  }
  .cancel-button {
    margin: 10px;
    line-height: 18px;
    font-size: 12px;
    color: #e00000;
  }
}
</style>
