<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
    <path d="M6.99998 1.83334C10.0008 1.83334 12.4569 4.16593 12.6539 7.11687C12.63 7.10018 12.6045 7.08381 12.5774 7.06814C12.3915 6.96083 12.2156 6.9367 12.1525 6.92898C12.0668 6.9185 11.9893 6.9179 11.9326 6.91928C11.8175 6.92207 11.699 6.93565 11.5944 6.95111C11.3801 6.98276 11.1311 7.03635 10.893 7.0994C10.6566 7.162 10.4007 7.24185 10.1794 7.33317C10.0703 7.37815 9.94561 7.43567 9.82803 7.5073C9.75034 7.55464 9.54498 7.6831 9.39518 7.91583L8.66665 6.94445V3.50001C8.66665 2.57954 7.92045 1.83334 6.99998 1.83334ZM6.99998 1.83334C6.53441 1.83334 6.08194 1.88949 5.649 1.99538C5.75869 2.05166 5.86096 2.12165 5.95324 2.20298C6.23935 1.97179 6.60349 1.83334 6.99998 1.83334ZM5.33331 4.81449V7.50001C5.33331 7.86063 5.45028 8.21152 5.66665 8.50001L7.26665 10.6333C7.27043 10.6384 7.27425 10.6434 7.27808 10.6484C6.9341 10.6788 6.58615 10.6527 6.24821 10.57C5.72255 10.4412 5.23875 10.1797 4.84315 9.8104C4.44755 9.44109 4.15341 8.97639 3.98889 8.46082C3.82437 7.94524 3.795 7.39605 3.90358 6.86587C4.01217 6.33568 4.25507 5.84226 4.60902 5.43286C4.81866 5.19038 5.06309 4.98219 5.33331 4.81449ZM11.044 7.95328C11.0441 7.95328 11.0455 7.95474 11.0482 7.95763C11.0452 7.95473 11.0439 7.95329 11.044 7.95328Z" fill="#0559CB" stroke="#0559CB" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'Clock',
}
</script>
