<template>
 <svg xmlns="http://www.w3.org/2000/svg" width="134" height="134" viewBox="0 0 134 134" fill="none">
    <path d="M37.2563 48.8182C50.851 48.8182 61.8717 37.9645 61.8717 24.5757C61.8717 11.187 50.851 0.333313 37.2563 0.333313C23.6616 0.333313 12.6409 11.187 12.6409 24.5757C12.6409 37.9645 23.6616 48.8182 37.2563 48.8182Z" fill="#CCCCCC"/>
    <path d="M6.04754 60.5065C13.5406 53.127 65.2482 53.0049 74.2038 60.1403C61.8769 67.7802 53.6666 81.4313 53.6666 97C53.6666 110.534 59.8714 122.62 69.5916 130.566C54.0133 135.418 12.6847 134.576 6.04754 128.039C-1.57151 120.535 -1.57151 68.0101 6.04754 60.5065Z" fill="#CCCCCC"/>
    <path d="M96.9999 109C103.627 109 109 103.627 109 97C109 90.3726 103.627 85 96.9999 85C90.3725 85 84.9999 90.3726 84.9999 97C84.9999 103.627 90.3725 109 96.9999 109Z" fill="#CCCCCC"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M133.667 97C133.667 117.25 117.25 133.667 96.9999 133.667C76.7495 133.667 60.3333 117.25 60.3333 97C60.3333 76.7495 76.7495 60.3333 96.9999 60.3333C117.25 60.3333 133.667 76.7495 133.667 97ZM91.8571 71.2857C92.9999 65.5714 101 65.5714 102.143 71.2857C103.021 75.6789 107.818 77.6659 111.546 75.1807C116.395 71.9482 122.052 77.6051 118.819 82.4538C116.334 86.1816 118.321 90.9785 122.714 91.8571C128.428 93 128.428 101 122.714 102.143C118.321 103.022 116.334 107.818 118.819 111.546C122.052 116.395 116.395 122.052 111.546 118.819C107.818 116.334 103.021 118.321 102.143 122.714C101 128.429 92.9999 128.429 91.8571 122.714C90.9784 118.321 86.1815 116.334 82.4537 118.819C77.605 122.052 71.9481 116.395 75.1806 111.546C77.6658 107.818 75.6789 103.021 71.2856 102.143C65.5714 101 65.5714 93 71.2856 91.8571C75.6789 90.9785 77.6658 86.1816 75.1806 82.4538C71.9481 77.605 77.605 71.9482 82.4537 75.1807C86.1815 77.6659 90.9784 75.6789 91.8571 71.2857Z" fill="#CCCCCC"/>
</svg>
</template>

<script>
export default {
  name: 'UserSetting',
}
</script>
