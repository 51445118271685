<template>
  <a-modal
    :visible="showModalFilterUser"
    :footer="null"
    class="filter-user-modal"
    @cancel="() => $emit('handleShowModal','close',false)"
  >
    <a-row class="pbz-font">
      <a-col :span="24" style="margin: 10px 0">
        <div style="font-size: 24px">
          Filter
        </div>
      </a-col>
      <a-col :span="24" style="margin: 10px 0">
        <div>Username</div>
      </a-col>
      <a-col :span="24" class="search-box" style="margin: 10px 0">
        <SearchIcon class="icon" style="color: #999999" />
        <a-input
          v-model="inputUsername"
          class="field"
          type="text"
          :placeholder="$t('userManage.search') + ' Username'"
          list="list-username"
          @input="handleInputUsername"
          @pressEnter="handleEnterUsername"
        />
        <datalist id="list-username">
          <option v-for="(item, index) in user" :key="index">
            {{ item.username }}
          </option>
        </datalist>
      </a-col>
      <a-col class="d-inline-block" :span="24" style="margin: 10px 0">
        <div v-for="(val, index) in tag.username" :key="index" class="tag-box" style="margin: 10px 5px">
          <p> {{ val }} </p>
          <button class="x-button" @click="removeTag(val, 'username')">
            x
          </button>
        </div>
      </a-col>
      <a-col :span="24" style="margin: 10px 0">
        <div>Email</div>
      </a-col>
      <a-col :span="24" class="search-box" style="margin: 10px 0">
        <SearchIcon class="icon" style="color: #999999" />
        <a-input
          v-model="inputEmail"
          class="field"
          type="text"
          :placeholder="$t('userManage.search') + ' Alamat Email'"
          list="list-email"
          @input="handleInputEmail"
          @pressEnter="handleEnterEmail"
        />
        <datalist id="list-email">
          <option v-for="(item, index) in user" :key="index">
            {{ item.email }}
          </option>
        </datalist>
      </a-col>
      <a-col class="d-inline-block" :span="24" style="margin: 10px 0">
        <div v-for="(email, index) in tag.email" :key="index" class="tag-box" style="margin: 10px 5px">
          <p> {{ email }} </p>
          <button class="x-button" @click="removeTag(email, 'email')">
            x
          </button>
        </div>
      </a-col>
      <a-col :span="24" style="margin: 10px 0">
        <div>Role</div>
      </a-col>
      <a-col :span="24" style="margin: 10px 0">
        <a-select
          v-model="inputRoles"
          placeholder="Pilih Role"
          style="width: 100%; height: 100%;"
        >
          <a-select-option v-for="(item, index) in businessRoles" :key="index" :value="item.workspace_role_id">
            {{ item.role.name }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" style="margin-top: 20px">
        <a-col :span="12">
          <a-button class="cancel-button" @click="() => $emit('resetFilter')">
            Bersihkan filter
          </a-button>
        </a-col>
        <a-col :span="6" style="display: flex; justify-content: flex-end;">
          <a-button class="cancel-button" @click="() => $emit('handleShowModal','close',false)">
            Batal
          </a-button>
        </a-col>
        <a-col :span="6" style="display: flex; justify-content: flex-end;">
          <a-button class="accept-button" @click="submitFilter">
            Terapkan
          </a-button>
        </a-col>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { findUser } from '@/api/user'
import SearchIcon from '@/components/Icons/Search.vue'

export default {
  name: 'UserManagementFilter',
  components: {
    SearchIcon,
  },
  props: {
    showModalFilterUser: {
      type: Boolean,
      default: () => false,
    },
    // eslint-disable-next-line vue/require-default-prop
    businessRoles: {
      type: Array,
    },
    // user: {
    //   type: Array,
    // },
  },
  data: function () {
    return {
      tag: {
        username: [],
        email: [],
      },
      inputUsername: '',
      inputEmail: '',
      inputRoles: null,
      user: [],
    }
  },
  watch: {
    '$route.query'() {
      this.tag.username = this.$route.query.username ? this.$route.query.username.split(',') : []
      this.tag.email = this.$route.query.email ? this.$route.query.email.split(',') : []
      this.inputRoles = this.$route.query.workspace_role_id ? this.$route.query.workspace_role_id : null
    },
  },
  created() {
    this.tag.username = this.$route.query.username ? this.$route.query.username.split(',') : []
    this.tag.email = this.$route.query.email ? this.$route.query.email.split(',') : []
    this.inputRoles = this.$route.query.workspace_role_id ? this.$route.query.workspace_role_id : null
  },
  methods: {
    async fetchUserList(param) {
      let query = {
        ...this.$route.query,
      }
      if (param === 'username') {
        query = {
          ...this.$route.query,
          username: this.inputUsername,
        }
      }
      if (param === 'email') {
        query = {
          ...this.$route.query,
          email: this.inputEmail,
        }
      }
      await findUser(query)
      .then(({ data: { data: response } }) => this.user = response)
      .catch((err) => console.error(err))
    },
    handleInputUsername() {
      this.fetchUserList('username')
      this.user.map(item => {
        if (item.username === this.inputUsername && item.username !== '') {
          this.tag.username.push(this.inputUsername)
          this.inputUsername = ''
          this.user = []
        }
      })
    },
    handleEnterUsername(event) {
      this.tag.username.push(this.inputUsername)
      this.inputUsername = ''
      this.user = []
      event.target.blur()
    },
    handleInputEmail() {
      this.fetchUserList('email')
      this.user.map(item => {
        if (item.email === this.inputEmail && item.email !== '') {
          this.tag.email = [...this.tag.email, this.inputEmail]
          this.inputEmail = ''
          this.user = []
        }
      })
    },
    handleEnterEmail(event) {
      this.tag.email.push(this.inputEmail)
      this.inputEmail = ''
      this.user = []
      event.target.blur()
    },
    removeTag(param, type) {
      switch (type) {
        case 'username' :
          this.tag.username = this.tag.username.filter(item => item !== param)
          break
        case 'email':
          this.tag.email = this.tag.email.filter(item => item !== param)
      }
    },
    submitFilter() {
      this.$router.push({
        query: {
          ...this.$route.query,
          username: this.tag.username.length !== 0 ? this.tag.username.join(',') : undefined,
          email: this.tag.email.length !== 0 ? this.tag.email.join(',') : undefined,
          workspace_role_id: this.inputRoles !== null ? this.inputRoles : undefined,
        },
      })
      this.$emit('handleShowModal', 'close', false)
    },
  },
}
</script>

<style lang='scss'>
.filter-user-modal{
  .field {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    text-align: left;
    padding-left: 2.5rem !important;
    padding: 10px 15px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    color: #999999;
  }
  .search-box {
    width: 100%;
  }
  .icon {
    position: absolute;
    top: 14px;
    left: 9px;
    z-index: 1;
  }
  .tag-box {
    display: inline-flex;
    position: static;
    width: auto;
    height: 31px;
    background: #4D4D4D;
    box-shadow: 0px 5px 5.3625px rgba(0, 0, 0, 0.055);
    border-radius: 33px;
    margin: 0 5px;
    padding: 5px 15px;
    p {
      position: static;
      color: #ffffff;
      align-items: center;
      text-align: center;
    }
  }
  .x-button {
    color:#ffffff;
    background:#4D4D4D;
    border:none;
    line-height: 21px;
    padding: 0 0 5px 7px;
  }
  .accept-button {
    background: #E00000;
    width: auto;
    line-height: 21px;
    border-radius: 5px;
    color: #ffffff;
  }

  .cancel-button {
    width: auto;
    line-height: 21px;
    color: #E00000;
    border: none;
  }
}

</style>
